import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { Category } from '../_models';

@Injectable({ providedIn: 'root' })
export class CategoryService {
    constructor(public http: HttpClient) { }

    public getAll(filter = {params:{}}) {
        return this.http.get<any>(`category/list`, filter);
    }

    getById(id: number) {
        return this.http.get(`category/edit/` + id);
    }

    insert(category: any) {
        return this.http.post(`category/add`, category);
    }

    update(id:number, category: any) {
        return this.http.post(`category/update/` + id, category);
    }

    delete(id: number) {
        return this.http.get(`category/delete/` + id);
    }
}
