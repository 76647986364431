import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {AngularEditorConfig} from '@kolkov/angular-editor';

import { MustMatch } from '../../_helpers/must-match.validator';
import { AlertService, ServiceproviderService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'serviceprovider.add.html'})
export class ServiceproviderAdd implements OnInit {
    heading = 'Add Service Provider';
    subheading = '';
    icon = 'fa fa-user-plus icon-gradient bg-happy-itmeo';
    serviceproviderForm: FormGroup;
    loading = false;
    submitted = false;
    serviceprovider_logo = null;
    preview_serviceprovider_logo = null;

    wyswigconfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ]
    };

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private serviceproviderService: ServiceproviderService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.serviceproviderForm = this.formBuilder.group({
            serviceprovider_name: ['', [Validators.required, Utils.noWhitespaceValidator]],
            serviceprovider_description: ['', Validators.required],
            serviceprovider_logo: ['', [Validators.required, Utils.noWhitespaceValidator]],
            serviceprovider_email: ['', [Validators.required, Utils.noWhitespaceValidator, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            serviceprovider_password: ['', [Validators.required, Utils.noWhitespaceValidator]],
            serviceprovider_confirm_password: ['', [Validators.required, Utils.noWhitespaceValidator]],
            serviceprovider_status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        }, {
            validator: MustMatch('serviceprovider_password', 'serviceprovider_confirm_password')
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.serviceproviderForm.controls; }

    IconChange(files: FileList) {
        this.serviceprovider_logo = files[0];
        this.serviceproviderForm.get('serviceprovider_logo').setValue(this.serviceprovider_logo.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.serviceprovider_logo); 
        reader.onload = (_event) => { 
          this.preview_serviceprovider_logo = reader.result; 
        }
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.serviceproviderForm.invalid) {
            return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('serviceprovider_name', this.serviceproviderForm.get('serviceprovider_name').value);
        formData.append('serviceprovider_description', this.serviceproviderForm.get('serviceprovider_description').value);
        formData.append('serviceprovider_logo', this.serviceprovider_logo, this.serviceprovider_logo.name);
        formData.append('serviceprovider_email', this.serviceproviderForm.get('serviceprovider_email').value);
        formData.append('serviceprovider_password', this.serviceproviderForm.get('serviceprovider_password').value);
        formData.append('serviceprovider_status', this.serviceproviderForm.get('serviceprovider_status').value);

        this.serviceproviderService.insert(formData).pipe(first()).subscribe((data: any) => {
              if(data.status=='ok') {
                this.alertService.success(data.message, true);
                this.router.navigate(['/serviceprovider']);
              } else {
                this.alertService.error(data.message, true);
              }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}