import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, ServiceproviderService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'serviceprovider.view.html'})
export class ServiceproviderView implements OnInit {
    heading = 'View Service Provider';
    subheading = '';
    icon = 'fa fa-user-plus icon-gradient bg-happy-itmeo';
    serviceproviderForm: FormGroup;
    loading = false;
    submitted = false;
    serviceprovider_id: number;
    preview_serviceprovider_logo = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private serviceproviderService: ServiceproviderService,
        private alertService: AlertService
    ) { 
        this.serviceprovider_id = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        this.getServiceprovider(this.serviceprovider_id);
        this.serviceproviderForm = this.formBuilder.group({
            serviceprovider_name: ['', [Validators.required, Utils.noWhitespaceValidator]],
            serviceprovider_description: ['', [Validators.required, Utils.noWhitespaceValidator]],
            serviceprovider_email: ['', [Validators.required, Utils.noWhitespaceValidator]],
            serviceprovider_status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.serviceproviderForm.controls; }

    getServiceprovider(id) {
        this.serviceproviderService.getById(id).subscribe((data: any) => {
            var serviceprovider = data.result_set;
            this.serviceproviderForm.setValue({
              serviceprovider_name: serviceprovider.serviceprovider_name,
              serviceprovider_description: serviceprovider.serviceprovider_description1,
              serviceprovider_email: serviceprovider.serviceprovider_email,
              serviceprovider_status: serviceprovider.serviceprovider_status
            });
            this.preview_serviceprovider_logo = serviceprovider.serviceprovider_logo;
        });
    }

    getControlLabel(type: string){
     return this.serviceproviderForm.controls[type].value;
    }
}