import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {MatTableDataSource, MatSort, MatPaginator} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';

// import { User } from '../../_models';
import { AlertService, UserService } from '../../_services';

@Component({ templateUrl: 'user.list.html' })
export class UserList implements OnInit, OnDestroy {
    
    heading = 'Users';
	subheading = '';
	icon = 'fa fa-user icon-gradient bg-happy-itmeo';
	link = '/user/add';
    users: [];
    dataSource: MatTableDataSource<any[]>;
    search_key = "";
    pageSizeOptions = null;

    displayedColumns = ['s_no','firstname', 'lastname', 'username', 'email', 'status', 'actions'];
    

    @ViewChild(MatPaginator) paginator: MatPaginator;  
    @ViewChild(MatSort) sort: MatSort;
    
    setPageSizeOptions(setPageSizeOptionsInput: string) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

    constructor( 
        private userService: UserService,
        private alertService: AlertService ) { }

    ngOnInit() {
        this.loadAllUsers();
    }

    ngOnDestroy() {
    }

    clearFilters(){
       this.dataSource.filter = '';
       this.search_key = '';
    }

    private loadAllUsers() {
        this.userService.getAll().pipe(first()).subscribe((users: any) => {
        	if(users.status=='ok') {
                this.dataSource = new MatTableDataSource(users.result_set);
                this.dataSource.paginator = this.paginator;  
                this.dataSource.sort = this.sort; 
        	}
        });
    }

    deleteUser(id: number) {
        this.alertService.delete().then(data=>{
            if(data) {
                this.userService.delete(id).pipe(first()).subscribe((data: any) => {
                    if(data.status=='ok') {
                      this.alertService.success(data.message, true);
                      this.loadAllUsers()
                    } else {
                      this.alertService.error(data.message, true);
                    }
                });
            } 
        });
    }

    applyFilter(filterValue: string) {  
      this.dataSource.filter = filterValue.trim().toLowerCase();  
    
      if (this.dataSource.paginator) {  
        this.dataSource.paginator.firstPage();  
      }  
    }

}
