import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {MatTableDataSource, MatSort, MatPaginator} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';

// import { Merchant } from '../../_models';
import { AlertService, MerchantService } from '../../_services';

@Component({ templateUrl: 'merchant.list.html' })
export class MerchantList implements OnInit, OnDestroy {
    
    heading = 'Merchants';
	  subheading = '';
	  icon = 'fa fa-simplybuilt icon-gradient bg-happy-itmeo';
	  link = '/merchant/add';
    merchants: [];
    dataSource: MatTableDataSource<any[]>;
    search_key = "";
    pageSizeOptions = null;

    displayedColumns = [ 's_no','merchant_name', 'merchant_status', 'merchant_created_on', 'actions'];
    

    @ViewChild(MatPaginator) paginator: MatPaginator;  
    @ViewChild(MatSort) sort: MatSort;
    
    setPageSizeOptions(setPageSizeOptionsInput: string) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

    constructor( 
        private merchantServices: MerchantService,
        private alertService: AlertService ) { }

    ngOnInit() {
        this.loadAllMerchants();
    }

    ngOnDestroy() {
    }

    clearFilters(){
       this.dataSource.filter = '';
       this.search_key = '';
    }

    private loadAllMerchants() {
        this.merchantServices.getAll().pipe(first()).subscribe((merchants: any) => {
        	if(merchants.status=='ok') {
            // this.merchants = merchants.result_set;
            this.dataSource = new MatTableDataSource(merchants.result_set);
            this.dataSource.paginator = this.paginator;  
            this.dataSource.sort = this.sort; 
        	}
        });
    }

    deleteMerchant(id: number) {
        this.alertService.delete().then(data=>{
            if(data) {
                this.merchantServices.delete(id).pipe(first()).subscribe((data: any) => {
                    if(data.status=='ok') {
                      this.alertService.success(data.message, true);
                      this.loadAllMerchants()
                    } else {
                      this.alertService.error(data.message, true);
                    }
                });
            } 
        });
    }

    applyFilter(filterValue: string) {  
      this.dataSource.filter = filterValue.trim().toLowerCase();  
    
      if (this.dataSource.paginator) {  
        this.dataSource.paginator.firstPage();  
      }  
    }

}
