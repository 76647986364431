import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any[]>;
    public currentUser: Observable<any[]>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any[]>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    login(user: any) {
        return this.http.post<any>(`user/login`, user)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user.status=='ok' && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user.result_set));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        this.http.get(`user/logout`);
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}