import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';

import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';

import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';

import { HomeComponent } from './Pages/Home/home.component';
import { SettingsComponent } from './Pages/Home/settings.component';
import { LoginComponent } from './Pages/Login/login.component';

// CATEGORY
import { CategoryList } from './Pages/Category/category.list';
import { CategoryAdd } from './Pages/Category/category.add';
import { CategoryEdit } from './Pages/Category/category.edit';
import { CategoryView } from './Pages/Category/category.view';

// MERCHANT
import { MerchantList } from './Pages/Merchant/merchant.list';
import { MerchantAdd } from './Pages/Merchant/merchant.add';
import { MerchantEdit } from './Pages/Merchant/merchant.edit';
import { MerchantView } from './Pages/Merchant/merchant.view';

// SERVICE PROVIDER
import { ServiceproviderList } from './Pages/Serviceprovider/serviceprovider.list';
import { ServiceproviderAdd } from './Pages/Serviceprovider/serviceprovider.add';
import { ServiceproviderEdit } from './Pages/Serviceprovider/serviceprovider.edit';
import { ServiceproviderView } from './Pages/Serviceprovider/serviceprovider.view';

// PROJECT
import { ProjectList } from './Pages/Project/project.list';
import { ProjectAdd } from './Pages/Project/project.add';
import { ProjectEdit } from './Pages/Project/project.edit';
import { ProjectView } from './Pages/Project/project.view';

// USER
import { UserList } from './Pages/User/user.list';
import { UserAdd } from './Pages/User/user.add';
import { UserEdit } from './Pages/User/user.edit';
import { UserView } from './Pages/User/user.view';
import { UserProfile } from './Pages/User/user.profile';


const appRoutes: Routes = [
	{
	    path: '',
	    component: BaseLayoutComponent,
	    canActivate: [AuthGuard],
	    children: [
	      {path: '', component: HomeComponent, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'settings', component: SettingsComponent, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'category', component: CategoryList, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'category/add', component: CategoryAdd, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'category/edit/:id', component: CategoryEdit, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'category/view/:id', component: CategoryView, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'merchant', component: MerchantList, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'merchant/add', component: MerchantAdd, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'merchant/edit/:id', component: MerchantEdit, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'merchant/view/:id', component: MerchantView, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'serviceprovider', component: ServiceproviderList, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'serviceprovider/add', component: ServiceproviderAdd, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'serviceprovider/edit/:id', component: ServiceproviderEdit, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'serviceprovider/view/:id', component: ServiceproviderView, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'project', component: ProjectList, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'project/add', component: ProjectAdd, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'project/edit/:id', component: ProjectEdit, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'project/view/:id', component: ProjectView, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'user', component: UserList, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'user/add', component: UserAdd, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'user/edit/:id', component: UserEdit, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'user/view/:id', component: UserView, data: {extraParameter: 'dashboardsMenu'}},
	      {path: 'profile', component: UserProfile, data: {extraParameter: 'dashboardsMenu'}}
	    ]
	},
	{
    path: '',
    component: PagesLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent, data: {extraParameter: ''}}
    ]
  },
]



export const routing = RouterModule.forRoot(appRoutes);