import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { MustMatch } from '../../_helpers/must-match.validator';
import { AlertService, UserService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'user.view.html'})
export class UserView implements OnInit {
    heading = 'View User';
    subheading = '';
    icon = 'fa fa-user icon-gradient bg-happy-itmeo';
    userForm: FormGroup;
    loading = false;
    submitted = false;
    preview_admin_profile = null;
    user_id: number;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private userService: UserService        
    ) {  
        this.user_id = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        this.getUser(this.user_id);
        this.userForm = this.formBuilder.group({
            username: ['', [Validators.required, Utils.noWhitespaceValidator]],
            firstname: ['', [Validators.required, Utils.noWhitespaceValidator]],
            lastname: ['', [Validators.required, Utils.noWhitespaceValidator]],
            email: ['', [Validators.required, Utils.noWhitespaceValidator, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.userForm.controls; }

    getUser(id) {
        this.userService.getById(id).subscribe((data: any) => {
            var user = data.result_set;
            this.userForm.setValue({
              firstname: user.firstname,
              lastname: user.lastname,
              username: user.username,
              email: user.email,
              status: user.status
            });
            this.preview_admin_profile = user.admin_profile;
        });
    }

    getControlLabel(type: string){
     return this.userForm.controls[type].value;
    }

}