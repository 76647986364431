import {Component, OnInit, NgZone} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {ThemeOptions} from '../../../../../theme-options';

import { AlertService, AuthenticationService, UserService } from '../../../../../_services';
// import { User } from '../../../../../_models';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {
	currentUser= null;
  currentUserSubscription: Subscription;
  profile_pic = null;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(
  	public globals: ThemeOptions,
  	private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService
  ) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.userService.getProfile().pipe(first()).subscribe((user: any) => {
      if(user.status=='ok') {
        this.currentUser = user.result_set;
        this.profile_pic = this.currentUser.admin_profile;
      }
    });
  }

  logout() {
    this.authenticationService.logout();
    this.alertService.success("Successfully Logged-out ", true);
    this.router.navigate(['/login']);
  }

}
