import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(`user/list`);
    }

   getById(id: number) {
        return this.http.get<any[]>(`user/edit/` + id);
    }

    getProfile() {
        return this.http.get<any[]>(`user/profile`);
    }

    insert(user: any) {
        return this.http.post<any[]>(`user/insert`, user);
    }

    update(id:number, user: any) {
        return this.http.post<any[]>(`user/update/` + id, user);
    }

    delete(id: number) {
        return this.http.get<any[]>(`user/delete/` + id);
    }
}