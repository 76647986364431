import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {MatTableDataSource, MatSort, MatPaginator} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';

import { AlertService, ProjectService } from '../../_services';

@Component({ templateUrl: 'project.list.html' })
export class ProjectList implements OnInit, OnDestroy {
	
	heading = 'Projects';
  subheading = '';
  icon = 'fa fa-folder icon-gradient bg-happy-itmeo';
  link = '/project/add';
	projects: [];
	dataSource: MatTableDataSource<any[]>;
	search_key = "";
	pageSizeOptions = null;

	displayedColumns = [ 's_no','project_title', 'merchant_name', 'project_status', 'project_created_on', 'actions'];
	

	@ViewChild(MatPaginator) paginator: MatPaginator;  
	@ViewChild(MatSort) sort: MatSort;
	
	setPageSizeOptions(setPageSizeOptionsInput: string) {
	  this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}

	constructor( 
		private projectServices: ProjectService,
		private alertService: AlertService ) { }

	ngOnInit() {
		this.loadAllProjects();
	}

	ngOnDestroy() {
	}

	clearFilters(){
	   this.dataSource.filter = '';
	   this.search_key = '';
	}

	private loadAllProjects() {
		this.projectServices.getAll().pipe(first()).subscribe((projects: any) => {
			if(projects.status=='ok') {
			this.dataSource = new MatTableDataSource(projects.result_set);
			this.dataSource.paginator = this.paginator;  
			this.dataSource.sort = this.sort; 
			}
		});
	}

	deleteProject(id: number) {
		this.alertService.delete().then(data=>{
			if(data) {
				this.projectServices.delete(id).pipe(first()).subscribe((data: any) => {
					if(data.status=='ok') {
					  this.alertService.success(data.message, true);
					  this.loadAllProjects()
					} else {
					  this.alertService.error(data.message, true);
					}
				});
			} 
		});
	}

	applyFilter(filterValue: string) {  
	  this.dataSource.filter = filterValue.trim().toLowerCase();  
	
	  if (this.dataSource.paginator) {  
		this.dataSource.paginator.firstPage();  
	  }  
	}

}
