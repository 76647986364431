import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { MustMatch } from '../../_helpers/must-match.validator';
import { AlertService, UserService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({ templateUrl: 'user.add.html'})
export class UserAdd implements OnInit {
    heading = 'Add User';
    subheading = '';
    icon = 'fa fa-user icon-gradient bg-happy-itmeo';
    userForm: FormGroup;
    loading = false;
    submitted = false;
    admin_profile = null;
    preview_admin_profile = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private alertService: AlertService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.userForm = this.formBuilder.group({
            username: ['', [Validators.required, Utils.noWhitespaceValidator]],
            firstname: ['', [Validators.required, Utils.noWhitespaceValidator]],
            lastname: ['', [Validators.required, Utils.noWhitespaceValidator]],
            email: ['', [Validators.required, Utils.noWhitespaceValidator, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            password: ['', [Validators.required, Utils.noWhitespaceValidator]],
            confirm_password: ['', [Validators.required, Utils.noWhitespaceValidator]],
            admin_profile: ['', [Validators.required, Utils.noWhitespaceValidator]],
            status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        }, {
            validator: MustMatch('password', 'confirm_password')
        });
    }

    IconChange(files: FileList) {
        this.admin_profile = files[0];
        this.userForm.get('admin_profile').setValue(this.admin_profile.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.admin_profile); 
        reader.onload = (_event) => { 
          this.preview_admin_profile = reader.result; 
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.userForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.userForm.invalid) {
            return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('firstname', this.userForm.get('firstname').value);
        formData.append('lastname', this.userForm.get('lastname').value);
        formData.append('username', this.userForm.get('username').value);
        formData.append('email', this.userForm.get('email').value);
        formData.append('password', this.userForm.get('password').value);
        formData.append('status', this.userForm.get('status').value);
        formData.append('admin_profile', this.admin_profile, this.admin_profile.name);

        this.userService.insert(formData).pipe(first()).subscribe((data: any) => {
            if(data.status=='ok') {
              this.alertService.success(data.message, true);
              this.router.navigate(['/user']);
            } else {
              this.alertService.error(data.message, true);
            }
          },
          error => {
              this.alertService.error(error);
              this.loading = false;
          }
        );
    }
}