import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ServiceproviderService {
    constructor(public http: HttpClient) { }

    public getAll(filter = {params:{}}) {
        return this.http.get<any>(`serviceprovider/list`, filter);
    }

    getById(id: number) {
        return this.http.get(`serviceprovider/edit/` + id);
    }

    insert(serviceprovider: any) {
        return this.http.post(`serviceprovider/add`, serviceprovider);
    }

    update(id:number, serviceprovider: any) {
        return this.http.post(`serviceprovider/update/` + id, serviceprovider);
    }

    delete(id: number) {
        return this.http.get(`serviceprovider/delete/` + id);
    }
}
