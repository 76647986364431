import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {MatTableDataSource, MatSort, MatPaginator} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';

import { AlertService, ServiceproviderService } from '../../_services';

@Component({ templateUrl: 'serviceprovider.list.html' })
export class ServiceproviderList implements OnInit, OnDestroy {
    
    heading = 'Service Providers';
	  subheading = '';
	  icon = 'fa fa-user-plus icon-gradient bg-happy-itmeo';
	  link = '/serviceprovider/add';
    merchants: [];
    dataSource: MatTableDataSource<any[]>;
    search_key = "";
    pageSizeOptions = null;

    displayedColumns = [ 's_no','serviceprovider_name', 'serviceprovider_status', 'serviceprovider_created_on', 'actions'];
    

    @ViewChild(MatPaginator) paginator: MatPaginator;  
    @ViewChild(MatSort) sort: MatSort;
    
    setPageSizeOptions(setPageSizeOptionsInput: string) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

    constructor( 
        private serviceProviderServices: ServiceproviderService,
        private alertService: AlertService ) { }

    ngOnInit() {
        this.loadAllServiceproviders();
    }

    ngOnDestroy() {
    }

    clearFilters(){
       this.dataSource.filter = '';
       this.search_key = '';
    }

    private loadAllServiceproviders() {
        this.serviceProviderServices.getAll().pipe(first()).subscribe((serviceproviders: any) => {
        	if(serviceproviders.status=='ok') {
            this.dataSource = new MatTableDataSource(serviceproviders.result_set);
            this.dataSource.paginator = this.paginator;  
            this.dataSource.sort = this.sort; 
        	}
        });
    }

    deleteServiceprovider(id: number) {
        this.alertService.delete().then(data=>{
            if(data) {
                this.serviceProviderServices.delete(id).pipe(first()).subscribe((data: any) => {
                    if(data.status=='ok') {
                      this.alertService.success(data.message, true);
                      this.loadAllServiceproviders()
                    } else {
                      this.alertService.error(data.message, true);
                    }
                });
            } 
        });
    }

    applyFilter(filterValue: string) {  
      this.dataSource.filter = filterValue.trim().toLowerCase();  
    
      if (this.dataSource.paginator) {  
        this.dataSource.paginator.firstPage();  
      }  
    }

}
