import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { MustMatch } from '../../_helpers/must-match.validator';
import { AlertService, UserService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'user.edit.html'})
export class UserEdit implements OnInit {
    heading = 'Edit User';
    subheading = '';
    icon = 'fa fa-user icon-gradient bg-happy-itmeo';
    userForm: FormGroup;
    loading = false;
    submitted = false;
    admin_profile = null;
    preview_admin_profile = null;
    user_id: number;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private userService: UserService        
    ) {  
        this.user_id = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        this.getUser(this.user_id);
        this.userForm = this.formBuilder.group({
            username: ['', [Validators.required, Utils.noWhitespaceValidator]],
            firstname: ['', [Validators.required, Utils.noWhitespaceValidator]],
            lastname: ['', [Validators.required, Utils.noWhitespaceValidator]],
            email: ['', [Validators.required, Utils.noWhitespaceValidator, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            password: [''],
            confirm_password: [''],
            admin_profile: ['', [Validators.required, Utils.noWhitespaceValidator]],
            status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        }, {
            validator: MustMatch('password', 'confirm_password')
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.userForm.controls; }

    getUser(id) {
        this.userService.getById(id).subscribe((data: any) => {
            var user = data.result_set;
            this.userForm.setValue({
              firstname: user.firstname,
              lastname: user.lastname,
              username: user.username,
              email: user.email,
              password: '',
              confirm_password: '',
              admin_profile: user.admin_profile,
              status: user.status
            });
            this.preview_admin_profile = user.admin_profile;
        });
    }

    IconChange(files: FileList) {
        this.admin_profile = files[0];
        this.userForm.get('admin_profile').setValue(this.admin_profile.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.admin_profile); 
        reader.onload = (_event) => { 
          this.preview_admin_profile = reader.result; 
        }
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.userForm.invalid) {
            return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('firstname', this.userForm.get('firstname').value);
        formData.append('lastname', this.userForm.get('lastname').value);
        formData.append('username', this.userForm.get('username').value);
        formData.append('email', this.userForm.get('email').value);
        formData.append('password', this.userForm.get('password').value);
        formData.append('status', this.userForm.get('status').value);

        if(this.admin_profile!=null) {
          formData.append('admin_profile', this.admin_profile, this.admin_profile.name);
        }

        this.userService.update(this.user_id, formData).pipe(first()).subscribe((data: any) => {
              if(data.status=='ok') {
                this.alertService.success(data.message, true);
                this.router.navigate(['/user']);
              } else {
                this.alertService.error(data.message, true);
              }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}