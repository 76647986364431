import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { Merchant } from '../_models';

@Injectable({ providedIn: 'root' })
export class MerchantService {
    constructor(public http: HttpClient) { }

    public getAll(filter = {params:{}}) {
        return this.http.get<any>(`merchant/list`, filter);
    }

    getById(id: number) {
        return this.http.get(`merchant/edit/` + id);
    }

    insert(merchant: any) {
        return this.http.post(`merchant/add`, merchant);
    }

    update(id:number, merchant: any) {
        return this.http.post(`merchant/update/` + id, merchant);
    }

    delete(id: number) {
        return this.http.get(`merchant/delete/` + id);
    }
}
