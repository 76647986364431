import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, MerchantService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'merchant.view.html'})
export class MerchantView implements OnInit {
    heading = 'View Merchant';
    subheading = '';
    icon = 'fa fa-simplybuilt icon-gradient bg-happy-itmeo';
    merchantForm: FormGroup;
    loading = false;
    submitted = false;
    merchant_id: number;
    preview_merchant_logo = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private merchantService: MerchantService,
        private alertService: AlertService
    ) { 
        this.merchant_id = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        this.getMerchant(this.merchant_id);
        this.merchantForm = this.formBuilder.group({
            merchant_name: ['', [Validators.required, Utils.noWhitespaceValidator]],
            merchant_description: ['', [Validators.required, Utils.noWhitespaceValidator]],
            merchant_email: ['', [Validators.required, Utils.noWhitespaceValidator]],
            merchant_status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.merchantForm.controls; }

    getMerchant(id) {
        this.merchantService.getById(id).subscribe((data: any) => {
            var merchant = data.result_set;
            this.merchantForm.setValue({
              merchant_name: merchant.merchant_name,
              merchant_description: merchant.merchant_description1,
              merchant_email: merchant.merchant_email,
              merchant_status: merchant.merchant_status
            });
            this.preview_merchant_logo = merchant.merchant_logo;
        });
    }

    getControlLabel(type: string){
     return this.merchantForm.controls[type].value;
    }
}