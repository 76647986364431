import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { Merchant } from '../_models';

@Injectable({ providedIn: 'root' })
export class ProjectService {
    constructor(public http: HttpClient) { }

    public getAll(filter = {params:{}}) {
        return this.http.get<any>(`project/list`, filter);
    }

    getById(id: number) {
        return this.http.get(`project/edit/` + id);
    }

    insert(project: any) {
        return this.http.post(`project/add`, project);
    }

    update(id:number, project: any) {
        return this.http.post(`project/update/` + id, project);
    }

    delete(id: number) {
        return this.http.get(`project/delete/` + id);
    }
}
