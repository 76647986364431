import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {AngularEditorConfig} from '@kolkov/angular-editor';

import { AlertService, ProjectService, MerchantService, CategoryService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'project.add.html'})
export class ProjectAdd implements OnInit {
    heading = 'Add Project';
    subheading = '';
    icon = 'fa fa-folder icon-gradient bg-happy-itmeo';
    projectForm: FormGroup;
    loading = false;
    submitted = false;
    project_image = null;
    preview_project_image = null;
    merchants = [];
    categories = [];

    wyswigconfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ]
    };

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private projectService: ProjectService,
        private alertService: AlertService,
        private merchantServices: MerchantService,
        private categoryServices: CategoryService
    ) { }

    ngOnInit() {
        this.projectForm = this.formBuilder.group({
            project_title: ['', [Validators.required, Utils.noWhitespaceValidator]],
            project_description: ['', Validators.required],
            project_short_description: ['', Validators.required],
            project_image: [''],
            project_fund: ['', [Validators.required, Validators.min(1)]],
            project_challenge_type: ['Fixed', Validators.required],
            project_duration: ['', [Validators.required, Validators.min(1)]],
            project_location: ['', Validators.required],
            project_merchant_id: ['', [Validators.required, Utils.noWhitespaceValidator]],
            project_category_id: ['', [Validators.required, Utils.noWhitespaceValidator]],
            project_status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
        this.loadAllMerchants();
        this.loadAllCategories();
    }

    // convenience getter for easy access to form fields
    get f() { return this.projectForm.controls; }

    private loadAllMerchants() {
        this.merchantServices.getAll().pipe(first()).subscribe((merchants: any) => {
            if(merchants.status=='ok') {
                this.merchants = merchants.result_set;
            }
        });
    }

    private loadAllCategories() {
        this.categoryServices.getAll().pipe(first()).subscribe((categories: any) => {
            if(categories.status=='ok') {
                this.categories = categories.result_set;
            }
        });
    }

    IconChange(files: FileList) {
        this.project_image = files[0];
        this.projectForm.get('project_image').setValue(this.project_image.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.project_image); 
        reader.onload = (_event) => { 
          this.preview_project_image = reader.result; 
        }
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.projectForm.invalid) {
            return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('project_title', this.projectForm.get('project_title').value);
        formData.append('project_description', this.projectForm.get('project_description').value);
        formData.append('project_short_description', this.projectForm.get('project_short_description').value);
        formData.append('project_image', this.project_image, this.project_image.name);
        formData.append('project_location', this.projectForm.get('project_location').value);
        formData.append('project_merchant_id', this.projectForm.get('project_merchant_id').value);
        formData.append('project_category_id', this.projectForm.get('project_category_id').value);
        formData.append('project_fund', this.projectForm.get('project_fund').value);
        formData.append('project_challenge_type', this.projectForm.get('project_challenge_type').value);
        formData.append('project_duration', this.projectForm.get('project_duration').value);
        formData.append('project_status', this.projectForm.get('project_status').value);

        this.projectService.insert(formData).pipe(first()).subscribe((data: any) => {
              if(data.status=='ok') {
                this.alertService.success(data.message, true);
                this.router.navigate(['/project']);
              } else {
                this.alertService.error(data.message, true);
              }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}