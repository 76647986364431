import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { Setting } from '../../_models';
import { SettingService, AlertService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({ templateUrl: 'settings.component.html' })
export class SettingsComponent implements OnInit, OnDestroy {
    heading = 'Settings';
    subheading = '';
    icon = 'fa fa-cog icon-gradient bg-happy-itmeo';
    settingForm: FormGroup;
    loading = false;
    submitted = false;
    site_logo = null;
    preview_site_logo = null;
    site_default_image = null;
    preview_site_default = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private settingService: SettingService,
        private alertService:AlertService
    ) {
       
    }

    ngOnInit() {
        this.getSettings();
        this.settingForm = this.formBuilder.group({
            site_name: ['', [Validators.required, Utils.noWhitespaceValidator]],
            site_email: ['', [Validators.required, Utils.noWhitespaceValidator]],
            site_logo: ['', [Validators.required, Utils.noWhitespaceValidator]],
            site_default_image: ['', [Validators.required, Utils.noWhitespaceValidator]],
            site_phoneno: ['', [Validators.required, Utils.noWhitespaceValidator]],
            site_copyright_text: ['', [Validators.required, Utils.noWhitespaceValidator]],
            // site_currency_symbol: ['', [Validators.required, Utils.noWhitespaceValidator]],
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.settingForm.controls; }

    ngOnDestroy() {
    }

    private getSettings() {
        this.settingService.getSetting().subscribe((data: any) => {
            var settings = data.result_set;
            this.settingForm.setValue({
              site_name: settings.site_name,
              site_email: settings.site_email,
              site_logo: settings.site_logo,
              site_default_image: settings.site_default_image,
              // site_currency_symbol: settings.site_currency_symbol,
              site_copyright_text: settings.site_copyright_text,
              site_phoneno: settings.site_phoneno
            });
            this.preview_site_logo = settings.site_logo;
            this.preview_site_default = settings.site_default_image;
        });
    } 

    IconChange(files: FileList) {
        this.site_logo = files[0];
        this.settingForm.get('site_logo').setValue(this.site_logo.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.site_logo); 
        reader.onload = (_event) => { 
          this.preview_site_logo = reader.result; 
        }
    }

    ImageChange(files: FileList) {
        this.site_default_image = files[0];
        this.settingForm.get('site_default_image').setValue(this.site_default_image.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.site_default_image); 
        reader.onload = (_event) => { 
          this.preview_site_default = reader.result; 
        }
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.settingForm.invalid) {
            return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('site_name', this.settingForm.get('site_name').value);
        formData.append('site_email', this.settingForm.get('site_email').value);
        formData.append('site_phoneno', this.settingForm.get('site_phoneno').value);
        formData.append('site_copyright_text', this.settingForm.get('site_copyright_text').value);
        // formData.append('site_currency_symbol', this.settingForm.get('site_currency_symbol').value);
        
        if(this.site_logo!=null) {
          formData.append('site_logo', this.site_logo, this.site_logo.name);
        }

        if(this.site_default_image!=null) {
          formData.append('default_image', this.site_default_image, this.site_default_image.name);
        }

        this.settingService.save(formData).pipe(first()).subscribe((data: any) => {
              if(data.status=='ok') {
                this.settingForm.reset();
                this.getSettings();
                this.alertService.success(data.message, true);
                this.router.navigate(['/settings']);
              } else {
                this.alertService.error(data.message, true);
              }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}