import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, CategoryService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'category.add.html'})
export class CategoryAdd implements OnInit {
    heading = 'Add Category';
    subheading = '';
    icon = 'pe-7s-photo-gallery icon-gradient bg-happy-itmeo';
    categoryForm: FormGroup;
    loading = false;
    submitted = false;
    category_image = null;
    preview_category_image = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private categoryService: CategoryService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.categoryForm = this.formBuilder.group({
            category_name: ['', [Validators.required, Utils.noWhitespaceValidator]],
            category_image: ['', [Validators.required, Utils.noWhitespaceValidator]],
            category_status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    IconChange(files: FileList) {
        this.category_image = files[0];
        this.categoryForm.get('category_image').setValue(this.category_image.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.category_image); 
        reader.onload = (_event) => { 
          this.preview_category_image = reader.result; 
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.categoryForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.categoryForm.invalid) {
            return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('category_name', this.categoryForm.get('category_name').value);
        formData.append('category_image', this.category_image, this.category_image.name);
        formData.append('category_status', this.categoryForm.get('category_status').value);

        this.categoryService.insert(formData).pipe(first()).subscribe((data: any) => {
              if(data.status=='ok') {
                this.alertService.success(data.message, true);
                this.router.navigate(['/category']);
              } else {
                this.alertService.error(data.message, true);
              }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}