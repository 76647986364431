import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, ProjectService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({templateUrl: 'project.view.html'})
export class ProjectView implements OnInit {
    heading = 'View Project';
    subheading = '';
    icon = 'fa fa-folder icon-gradient bg-happy-itmeo';
    projectForm: FormGroup;
    loading = false;
    submitted = false;
    project_id: number;
    preview_project_image = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private projectService: ProjectService,
        private alertService: AlertService
    ) { 
        this.project_id = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        this.getProject(this.project_id);
        this.projectForm = this.formBuilder.group({
            project_category_name: ['', [Validators.required, Utils.noWhitespaceValidator]],
            project_title: ['', [Validators.required, Utils.noWhitespaceValidator]],
            project_description: ['', Validators.required],
            project_image: ['', [Validators.required, Utils.noWhitespaceValidator]],
            project_fund: ['', Validators.required],
            project_challenge_type: ['', Validators.required],
            project_duration: ['', Validators.required],
            project_location: ['', [Validators.required, Utils.noWhitespaceValidator]],
            project_merchant_name: ['', [Validators.required, Utils.noWhitespaceValidator]],
            project_status: ['', [Validators.required, Utils.noWhitespaceValidator]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.projectForm.controls; }

    getProject(id) {
        this.projectService.getById(id).subscribe((data: any) => {
            var project = data.result_set;
            this.projectForm.setValue({
              project_title: project.project_title,
              project_description: project.project_description1,
              project_merchant_name: project.merchant_name,
              project_category_name: project.category_name,
              project_fund: project.project_fund,
              project_challenge_type: project.project_challenge_type,
              project_duration: project.project_duration,
              project_status: project.project_status,
              project_location: project.project_location,
              project_image: project.project_image
            });
            this.preview_project_image = project.project_image;
        });
    }

    getControlLabel(type: string){
     return this.projectForm.controls[type].value;
    }
}